<template>
  <v-container fluid class="order-detail d-flex flex-column px-0" v-if="order">
    <CategoryTitle :category="category" />
    <OrderDetailInfo
      class="card mb-4"
      :order="order"
      @addAllToCart="addAllToCart"
      @deleteOrder="deleteOrder"
      @editOrder="editOrder"
      @showPayment="showPayment = !showPayment"
    />
    <PaymentTypeList
      mode="order"
      :order-id="order.orderId"
      v-if="showPayment"
      :options="paymentTypeListOptions"
    />

    <v-row>
      <v-col cols="12" md="4" class="d-flex flex-column order-delivery-info">
        <CartInfoAddressCard
          :shippingAddress="order.shippingAddress"
          class="card cart-info mb-4"
        />
        <CartInfoTimeslotCard
          :shippingAddress="order.shippingAddress"
          :timeslot="order.timeslot"
          class="card cart-info"
        />
      </v-col>
      <v-col cols="12" md="8">
        <CheckoutSummary
          :orderCart="order"
          class="card summary h-100 default--text text--darken-2"
          :isOrderDetail="true"
        />
      </v-col>
    </v-row>

    <OrderDetailTable
      :suborders="order.suborders"
      :order="order"
      class="mt-16"
      :delivered="delivered"
    />

    <v-row
      no-gutters
      class="backbtn-container mt-5 justify-center justify-sm-start"
    >
      <v-btn color="white" text plain v-bind:to="'/profile/orders'">
        <v-icon class="primary white--text rounded-lg mr-3">$prev</v-icon>
        <span
          class="primary--text text-caption text-none font-weight-bold"
          v-html="$t('orders.button.backToOrders')"
        ></span>
      </v-btn>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.order-detail {
  .card {
    padding: 10px 20px !important;
    box-sizing: border-box;
    background-color: var(--v-grey-lighten3) !important;
    border: 1px solid var(--v-grey-lighten4) !important;
    border-radius: 8px;
    &:hover {
      background-color: var(--v-primary-lighten2);
    }
    &.cart-info {
      padding: 35px 0 27px 12px !important;
      ::v-deep .text,
      ::v-deep .value {
        line-height: 24px !important;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        padding: 20px !important;
      }
    }
    &.summary {
      ::v-deep span {
        color: #3a3a3a;
      }
    }
  }
}

@media print {
  .cart-info-cards {
    flex-direction: row !important;
    gap: 12px !important;
  }

  .cart-info-cards div:first-child {
    margin-bottom: 0 !important;
  }
}
</style>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard";
import CheckoutSummary from "@/components/cart/CheckoutSummary";
import CategoryTitle from "@/components/category/CategoryTitle";
import PaymentTypeList from "~/components/payment/PaymentTypeList";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapState, mapActions } from "vuex";

export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CheckoutSummary,
    CategoryTitle,
    PaymentTypeList
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    // canIRateOrder() {
    //   return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    // },
    enableSave() {
      return !this.valid;
    },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    },
    delivered() {
      return (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      );
    }
  },
  methods: {
    // getTimeslotTime(time) {
    //   if (time) {
    //     const parseTime = this.am_pm_to_hours(time);
    //     var d = new Date();
    //     var timeMatch = parseTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    //     d.setHours(parseInt(timeMatch[1]) + (timeMatch[3] ? 12 : 0));
    //     d.setMinutes(parseInt(timeMatch[2]) || 0);
    //     const timeOnly = this.$dayjs(d).format("HH:mm");
    //     return timeOnly;
    //   } else return "";
    // },
    // am_pm_to_hours(time) {
    //   var hours = Number(time.match(/^(\d+)/)[1]);
    //   var minutes = Number(time.match(/:(\d+)/)[1]);
    //   var AMPM = time.match(/\s(.*)$/)[1];
    //   if (AMPM == "PM" && hours < 12) hours = hours + 12;
    //   if (AMPM == "AM" && hours == 12) hours = hours - 12;
    //   var sHours = hours.toString();
    //   var sMinutes = minutes.toString();
    //   if (hours < 10) sHours = "0" + sHours;
    //   if (minutes < 10) sMinutes = "0" + sMinutes;
    //   return sHours + ":" + sMinutes;
    // },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    async addAllToCart({ orderId }) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          await OrderService.editOrder(orderId);
          this.loadCart();
          this.$router.push({
            name: "Checkout"
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async reload(orderId) {
      this.order = await OrderService.getOrder(orderId);
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
