<template>
  <v-row no-gutters align="center">
    <v-col cols="12" sm="5" class="d-flex align-center mb-3 mb-md-0">
      <img
        :src="item.product.mediaURL"
        class="mr-4"
        height="65px"
        width="65px"
        :alt="item.product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
      />
      <div class="d-flex flex-column default--text">
        <span class="text-body-2 font-weight-bold">
          {{ item.product.name }}
        </span>
        <span class="text-caption">{{ item.product.shortDescr }}</span>
        <span
          class="weight-unit font-weight-bold"
          v-if="
            item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
              item.product.productInfos.PACKAGE_DESCR
          "
        >
          {{ item.product.productInfos.PACKAGE_DESCR }}
        </span>
        <div
          v-if="
            itemAdjustment &&
              itemAdjustment.userGiftCertificateId &&
              itemAdjustment.giftCertificate
          "
          class="cart-item-info promo--text"
        >
          <em>{{ itemAdjustment.giftCertificate.name }}</em>
          <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
        </div>
      </div>
    </v-col>
    <v-col cols="1" class="d-flex justify-end">
      <ProductPromo
        :warehousePromo="{
          view: { body: 'Promo', cssClass: 'order_detail' }
        }"
        v-if="hasPromo"
      ></ProductPromo>
    </v-col>

    <v-col cols="4" sm="2" class="d-flex justify-center">
      <ProductPrice
        :product="calculatedProduct"
        :showStandard="false"
      ></ProductPrice>
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center">
      <!-- <OrderDetailQuantity :item="item" :delivered="delivered" /> -->
      <OrderDetailQuantity :item="item" />
    </v-col>
    <v-col cols="3" sm="2" offset-sm="0" class="d-flex justify-center">
      <strong class="gross-total">
        {{ $n(item.grossTotal, "currency") }}
      </strong>
    </v-col>
  </v-row>
</template>
<script>
import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductPrice from "@/components/product/ProductPrice.vue";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";

import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "OrderDetailRow",
  props: { item: { type: Object }, order: { type: Object } },
  components: { ProductPromo, ProductPrice, OrderDetailQuantity },
  computed: {
    hasPromo() {
      return (
        this.item.product.priceStandardDisplay &&
        this.item.unitPrice !== this.item.product.priceStandardDisplay
      );
    },
    calculatedProduct() {
      if (
        this.item.product.priceStandardDisplay &&
        this.item.unitPrice === this.item.product.priceStandardDisplay
      ) {
        let productTmp = cloneDeep(this.item.product);
        productTmp.priceStandardDisplay = null;
        productTmp.priceDisplay = this.item.unitPrice;
        return productTmp;
      }
      return this.item.product;
    },
    itemAdjustment() {
      if (
        this.order.orderAdjustmentSummary &&
        this.order.orderAdjustmentSummary.orderAdjustments
      ) {
        let adj = find(
          this.order.orderAdjustmentSummary.orderAdjustments,
          orderAdjustment =>
            orderAdjustment.orderItemId == parseInt(this.item.orderItemId) &&
            orderAdjustment.userGiftCertificateId &&
            orderAdjustment.giftCertificate
        );
        return adj;
      }
      return null;
    }
  }
};
</script>
