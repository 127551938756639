var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center mb-3 mb-md-0",attrs:{"cols":"12","sm":"5"}},[_c('img',{staticClass:"mr-4",attrs:{"src":_vm.item.product.mediaURL,"height":"65px","width":"65px","alt":_vm.item.product.name,"onerror":"this.onerror=null;this.src='/no-icon.png'"}}),_c('div',{staticClass:"d-flex flex-column default--text"},[_c('span',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.item.product.shortDescr))]),(
          _vm.item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
            _vm.item.product.productInfos.PACKAGE_DESCR
        )?_c('span',{staticClass:"weight-unit font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.productInfos.PACKAGE_DESCR)+" ")]):_vm._e(),(
          _vm.itemAdjustment &&
            _vm.itemAdjustment.userGiftCertificateId &&
            _vm.itemAdjustment.giftCertificate
        )?_c('div',{staticClass:"cart-item-info promo--text"},[_c('em',[_vm._v(_vm._s(_vm.itemAdjustment.giftCertificate.name))])]):_vm._e()])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}},[(_vm.hasPromo)?_c('ProductPromo',{attrs:{"warehousePromo":{
        view: { body: 'Promo', cssClass: 'order_detail' }
      }}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('ProductPrice',{attrs:{"product":_vm.calculatedProduct,"showStandard":false}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('OrderDetailQuantity',{attrs:{"item":_vm.item}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3","sm":"2","offset-sm":"0"}},[_c('strong',{staticClass:"gross-total"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }