<template>
  <div>
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="mb-11 px-5 order-table-header"
    >
      <v-col cols="6">
        <strong>{{ $t("orders.header.product") }}</strong>
      </v-col>

      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.unitPrice") }}</strong>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.qty") }}</strong>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.totalPrice") }}</strong>
      </v-col>
    </v-row>

    <v-list v-for="suborder in suborders" v-bind:key="suborder.suborderId">
      <v-list-item
        v-for="item in suborder.suborderItems"
        v-bind:key="item.itemId"
        :to="{ name: 'Product', params: { slug: item.product.slug } }"
        class="py-4"
      >
        <order-detail-row :item="item" :order="order" />
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import OrderDetailRow from "@/components/orders/OrderDetailRow.vue";

export default {
  name: "OrderDetailTable",
  props: {
    order: { type: Object },
    suborders: { type: Array },
    delivered: { type: Boolean }
  },
  components: {
    OrderDetailRow
  }
};
</script>
<style scoped lang="scss">
.weight-unit {
  font-size: 10px;
}
.v-list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  ::v-deep .promo-box {
    width: 38px;
    height: 38px;
    .promo-header,
    .promo-body,
    .promo-footer {
      font-size: 12px;
    }
  }
}

::v-deep .price {
  .old-price,
  .weight-unit,
  .price-um {
    font-size: 10px;
  }
  .cur-price {
    font-size: 18px;
    font-weight: 400;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .cur-price {
      font-size: 14px;
    }
  }
}
::v-deep .qty-box {
  border: 1px solid var(--v-grey-lighten1);
  border-radius: $border-radius-root;
  background: $white;
  color: var(--v-default-base);
  padding: 10px 15px;
  min-width: 120px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: auto;
    width: 100%;
  }
}

::v-deep .gross-total {
  font-size: 18px;
}
</style>
