<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex flex-column justify-center mr-2">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
          :small="!$vuetify.breakpoint.xs"
          class="primary white--text"
          icon
          fab
        >
          <v-icon>$qrCode</v-icon>
        </v-btn>
        <span class="primary--text text-center small">
          {{ $t("orders.button.qrCode") }}
        </span>
      </div>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="zoom-dialog"
      >
        <div class="zoom-dialog-content">
          <v-btn @click.stop="dialog = false" dense elevation="0">
            Chiudi
          </v-btn>
          <div class="locker-qr-code mt-4">
            <h2>Avvicina il QR code al locker</h2>
            <QrCode :codeValue="codeValue" :fullscreen="true" />
            <p>Codice sblocco locker: {{ codeValue }}</p>
          </div>
        </div>
      </v-dialog>
    </template>
    <span>Apri QR CODE per ritirare il tuo ordine</span>
  </v-tooltip>
</template>
<style scoped lang="scss">
.zoom-dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  .pinch-zoom-wrapper {
    flex-grow: 1;
  }
}
.locker-qr-code {
  text-align: center;
}
.small {
  font-size: 10px;
  min-height: 16px;
  text-transform: uppercase;
}
</style>
<script>
import QrCode from "@/components/QrCode";
export default {
  name: "OrderQrCode",
  props: {
    codeValue: { type: String, required: true },
    width: { type: Number, default: 150 },
    height: { type: Number, default: 150 }
  },
  components: { QrCode },
  data() {
    return { dialog: false };
  },
  computed: {
    imgsrc() {
      return `https://chart.googleapis.com/chart?chs=${this.width}x${this.height}&cht=qr&chl=${this.codeValue}`;
    },
    imgsrcFull() {
      return `https://chart.googleapis.com/chart?chs=360x360&cht=qr&chl=${this.codeValue}`;
    }
  }
};
</script>
